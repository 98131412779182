import { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/DashboardPage.vue') },
      { path: '/documents', component: () => import('pages/DocumentPage.vue') },
      { path: '/help', component: () => import('pages/QaPage.vue') },
      { path: '/invoices', component: () => import('pages/InvoicePage.vue') }
    ]
  },

  {
    path: '/login',
    component: () => import('layouts/LoginLayout.vue'),
    children: [{
      path: '',
      component: () => import('src/pages/LoginPage.vue'),
      meta: {
        titleName: 'Menerga'
      }
    }]
  },

  {
    path: '/glemt-passord',
    component: () => import('layouts/LoginLayout.vue'),
    children: [{
      path: '',
      component: () => import('src/pages/ForgotPasswordPage.vue'),
      meta: {
        titleName: 'Menerga'
      }
    }]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes

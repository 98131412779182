/* eslint-disable @typescript-eslint/no-explicit-any */
import { firebase } from './firebase-loader'
import { beginOnIdTokenChanged } from './firebase'
import { idChangedTriggers } from '@olibm/js1-vue/firestore/v1'
import { apiRouter, getCachedPortalConfig } from './rest'
import { boot } from 'quasar/wrappers'

import { useCurrentUserStore } from 'src/stores/currentUser'

if (process.env.DEV) console.warn('init: src/boot/login import')

export default boot(async function ({ store }) {
  if (process.env.DEV) console.warn('init: src/boot/login exec')

  const dcx = { begin: Date.now(), prev: Date.now() }
  const { primaryTenantId } = await getCachedPortalConfig()
  // const { primaryTenantId, primaryTenantMeta, loginProviders } = await getCachedPortalConfig();
  await debugms(dcx, 'getCachedPortalConfig done')

  // store.commit('p2Layout/setTenantMeta', primaryTenantMeta);
  // await debugms(dcx, 'setTenantMeta done');

  // store.commit('p2Auth/setLoginProviders', loginProviders);
  // await debugms(dcx, 'setLoginProviders done');

  await beginOnIdTokenChanged(async function ({ fbid, claims, bearer }) {
    await debugms(dcx, 'beginOnIdTokenChanged begin')

    const begin = Date.now()
    try {
      if (fbid) {
        if (process.env.DEV) {
          console.log('onIdTokenChanged', { id: fbid.toJSON(), claims })
        }
        console.log('Bearer', bearer)

        const whoAmI = await apiRouter({
          url: '/xrm-tenants/v1/login/whoAmI',
          auth: { bearer }
        })

        await debugms(dcx, 'whoAmI done', { whoAmI })

        if (!whoAmI.identities.length) {
          console.warn('No identities found for authed user', { whoAmI })
        }

        whoAmI.identities = whoAmI.identities.filter(x =>
          x.tenantId.includes('menerga')
        )
        console.log('whoAmI.identities: ', whoAmI.identities)
        let tenantId, identity
        const tryFindIdentity = tryId => {
          if (!identity && tryId) {
            identity = whoAmI.identities.find(x => x.tenantId === tryId)
          }
          tenantId = identity?.tenantId || ''
        }

        // Preferred identity is resolved in the following order
        const previousTenantId = localStorage.getItem('previousTenantId') || ''
        tryFindIdentity(previousTenantId)
        tryFindIdentity(whoAmI.defaultTenantId)
        tryFindIdentity(whoAmI.identities.find(x => x.contactId)?.tenantId)
        tryFindIdentity(whoAmI.identities[0]?.tenantId)
        tryFindIdentity(primaryTenantId)
        console.log('identity: ', identity)
        const contact = identity?.contact
        const tenantMeta =
          !identity || identity.tenantId === primaryTenantId
            ? whoAmI.primaryTenantMeta
            : await apiRouter({
              url: `/xrm-tenants/v1/tenants/${identity.tenantId}/tenantMeta`,
              auth: { bearer }
            })
        await debugms(dcx, 'getTenantMeta done', { tenantMeta })

        // store.commit('p2Layout/setTenantMeta', tenantMeta);
        // await debugms(dcx, 'setTenantMeta done');

        idChangedTriggers.forEach(fn => {
          fn({ tenantId, userId: fbid.uid }, store)
        })
        await debugms(dcx, 'idChangedTriggers done')

        const scopes = [...(identity?.portalScopes || [])]
        if (!scopes.length) {
          scopes.push('r:guest')
        }

        await debugms(dcx, 'currentUserSet begin')

        if (
          contact &&
          tenantId &&
          tenantId !== previousTenantId &&
          tenantId !== primaryTenantId
        ) {
          localStorage.setItem('previousTenantId', tenantId)
        }

        // contact.id = '611a63d77c1573c490a2bdb4';
        // identity.contactId = contact.id;
        // identity.contactEntityType = 'contactPersons';

        let userInfoRow: any = null
        if (contact?.id && identity?.contactEntityType) {
          try {
            userInfoRow = await apiRouter({
              url: `/xrm-db/v1beta1/tenants/${tenantId}/${identity.contactEntityType}/${contact.id}`,
              auth: { bearer }
            })
          } catch (err) {
            console.log('err: ', err)
          }
        }
        let customers
        const customerIds = userInfoRow?.rowrels.filter(x => x.etyp === 'customers').map(x => x.erid)

        if (identity?.contactEntityType === 'contactPersons') {
          try {
            customers = (await apiRouter({
              url: `/xrm-db/v1beta1/tenants/${tenantId}/customers/`,
              qs: {
                f_in_id: customerIds.join('|')
              },
              auth: { bearer }
            })).customers
          } catch (err) {
            console.log('err: ', err)
          }
        } else {
          customers = [userInfoRow]
        }

        let company = null

        if (customers.length && customers[0] != null) {
          company = customers.find(customer => customer.isCompany)
          userInfoRow = customers.find(customer => !customer.isCompany)
        }

        await useCurrentUserStore().setCurrentUser({
          uid: fbid.uid,
          contactId: contact?.id || '',
          contactEntityType: identity?.contactEntityType || null,
          displayName:
              contact?.firstname ||
              contact?.name ||
              fbid.displayName ||
              'Gjestebruker',
          authedEmail: fbid.email || '',
          authedEmailVerified: !!fbid.emailVerified,
          authedPhoneNumber: fbid.phoneNumber || '',
          scopes,
          tenantId,
          loginProvider: claims.firebase.sign_in_provider,
          avatarPhotoUrl: fbid.photoURL,
          tenantMeta,
          identities: whoAmI.identities,
          bearer, // TODO: remove bearer?
          userInfoRow,
          company
        })

        await debugms(dcx, 'currentUserSet done')
      } else {
        if (process.env.DEV) console.log('onIdTokenChanged (logout)')
        useCurrentUserStore().clearCurrentUser()
        idChangedTriggers.forEach(fn => {
          fn({ tenantId: 'none', userId: 'none' }, store)
        })
      }
    } catch (err: any) {
      console.warn('Error in login.onIdTokenChanged', err?.message)
      console.error(err)
      if (fbid) {
        console.warn('auto-signout due to error')
        firebase.auth().signOut()
      }
      if (!process.env.DEV) {
        console.warn('auto-location-reload due to error')
        location.reload()
      }
    } finally {
      if (process.env.DEV) {
        console.warn(
          `login.js beginOnIdTokenChanged took ${Date.now() - begin}ms`
        )
      }
    }
  })
})

async function debugms (cx, msg, ...args) {
  // eslint-disable-next-line promise/param-names
  await new Promise(r => setTimeout(r, 0))
  const ptook = Date.now() - cx.prev
  if (process.env.DEV || ptook > 500) {
    console[ptook > 100 ? 'warn' : 'log'](
      `debugms tot:${Date.now() - cx.begin} prev: ${ptook} msg:${msg}`,
      ...args
    )
    cx.prev = Date.now()
  }
}

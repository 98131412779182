import { defineStore } from 'pinia'
import { ICurrentUser } from 'src/components/common/models'

export const useCurrentUserStore = defineStore('currentUser', {
  state: (): ICurrentUser => {
    return {
      uid: null,
      contactId: null,
      contactEntityType: null,
      displayName: null,
      authedEmail: null,
      authedEmailVerified: false,
      authedPhoneNumber: null,
      scopes: null,
      tenantId: null,
      loginProvider: null,
      avatarPhotoUrl: null,
      tenantMeta: null,
      identities: null,
      bearer: null,
      userInfoRow: null,
      company: null
    }
  },

  getters: {
    getCurrentUser (state) {
      return state
    }
  },

  actions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCurrentUser (currentUser: ICurrentUser) {
      this.$state = currentUser
    },

    clearCurrentUser () {
      this.$state = {
        uid: null,
        contactId: null,
        contactEntityType: null,
        displayName: null,
        authedEmail: null,
        authedEmailVerified: false,
        authedPhoneNumber: null,
        scopes: null,
        tenantId: null,
        loginProvider: null,
        avatarPhotoUrl: null,
        tenantMeta: null,
        identities: null,
        bearer: null,
        userInfoRow: null,
        company: null
      }
    }
  }
})

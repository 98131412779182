import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import routes from './routes'
import { useCurrentUserStore } from 'src/stores/currentUser'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(
      process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE
    )
  })

  Router.beforeEach(async (to, from, next) => {
    if (to && from && to.path !== from.path) {
      // eslint-disable-next-line promise/param-names
      await new Promise(r => setTimeout(r, 1)) // TODO: hack to get ripple effect on buttons
    }

    if (to.path.startsWith('/login') || to.path.startsWith('/glemt-passord')) {
      return next()
    }
    const currentUser = useCurrentUserStore()
    if (!currentUser.uid) {
      if (process.env.DEV) console.warn('Redirecting to login page', { from: to.fullPath })
      return next({
        path: '/login',
        query: {
          goback: to.query.goback || to.fullPath
        }
      })
    }

    next()
  })

  return Router
})
